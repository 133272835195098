import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import Forms from '../Forms/form';

export default function ContactPageContactForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const contactFormId = '1029';

  const onSubmit = async (values, e) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('nameField', values.name);
      formData.append('email', values.email);
      formData.append('contact', values.telephone);
      formData.append('location', values.location);
      formData.append('village', values.village);
      formData.append('energy-solution', values.energySolution);
      formData.append('efl-connection', values.efl);
      formData.append('reason-for-contact', values.reasonOfContact);
      formData.append('message', values.message);
      const { data } = await axios.post(
        `${process.env.WPREST_URL}/contact-form-7/v1/contact-forms/${contactFormId}/feedback/`,
        formData,
      );

      setStatus({
        type: data.status.split('_')[1],
        message: data.message,
      });

      e.target.reset();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      reset();
    }
  };

  return (
    <Forms>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-field-container">
          <div className="form-row two-col">
            <div className="form-col">
              <label className="block" htmlFor="name">
                <span className="label-span text-gray-dark">Name</span>
                {/* eslint-disable react/jsx-props-no-spreading */}
                <input
                  type="text"
                  className="form-input mt-1 block w-full"
                  placeholder="Name"
                  id="name"
                  name="name"
                  {...register('name', { required: true })}
                />
                <span className="field-error">
                  {errors.name?.type === 'required' && '*Name is required'}
                </span>
              </label>
            </div>

            <div className="form-col">
              <label className="block" htmlFor="telephone">
                <span className="label-span text-gray-dark">Phone number</span>
                <input
                  type="tel"
                  className="form-input mt-1 block w-full"
                  placeholder="Phone number"
                  id="telephone"
                  name="telephone"
                  {...register('telephone', {
                    required: true,
                    pattern: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                  })}
                />
                <span className="field-error">
                  {errors.telephone?.type === 'required' && '*Telephone is required'}
                  {errors.telephone?.type === 'pattern' && '*Phone number is invalid'}
                </span>
              </label>
            </div>
          </div>
          <div className="form-row two-col">
            <div className="form-col">
              <label className="block" htmlFor="email">
                <span className="label-span text-gray-dark">Email Address</span>
                <input
                  type="email"
                  className="form-input mt-1 block w-full"
                  placeholder="Email Address"
                  id="email"
                  name="email"
                  {...register('email', { required: true })}
                />
                <span className="field-error">
                  {errors.email?.type === 'required' && '*Email is required'}
                </span>
              </label>
            </div>

            <div className="form-col">
              <label className="block" htmlFor="name">
                <span className="label-span text-gray-dark">Location (city or island)</span>
                <select
                  className="form-select block w-full mt-1"
                  id="location"
                  name="location"
                  {...register('location', { required: true })}>
                  <option value="Ba">Ba</option>
                  <option value="Bua">Bua</option>
                  <option value="Cakaudrove">Cakaudrove</option>
                  <option value="Kadavu">Kadavu</option>
                  <option value="Lau">Lau</option>
                  <option value="Lomaiviti">Lomaiviti</option>
                  <option value="Macuata">Macuata</option>
                  <option value="Nadroga-Navosa">Nadroga-Navosa</option>
                  <option value="Naitasiri">Naitasiri</option>
                  <option value="Namosi">Namosi</option>
                  <option value="Ra">Ra</option>
                  <option value="Rewa">Rewa</option>
                  <option value="Serua">Serua</option>
                  <option value="Tailevu">Tailevu</option>
                  <option value="Rotuma">Rotuma</option>
                </select>
                <span className="field-error">
                  {errors.location?.type === 'required' && '*Location is required'}
                </span>
              </label>
            </div>
          </div>
          <div className="form-row two-col">
            <div className="form-col">
              <label className="block" htmlFor="village">
                <span className="label-span text-gray-dark">Village</span>
                <input
                  type="text"
                  className="form-input mt-1 block w-full"
                  id="village"
                  name="village"
                  {...register('village')}
                />
              </label>
            </div>

            <div className="form-col">
              <label className="block" htmlFor="energySolution">
                <span className="label-span text-gray-dark">Energy Solution For</span>
                <input
                  type="text"
                  className="form-input mt-1 block w-full"
                  id="energySolution"
                  name="energySolution"
                  {...register('energySolution')}
                />
              </label>
            </div>
          </div>

          <div className="form-row two-col">
            <div className="form-col">
              <label className="block" htmlFor="name">
                <span className="label-span text-gray-dark">Do you have an EFL connection?</span>
                <select
                  className="form-select block w-full mt-1"
                  id="efl"
                  name="efl"
                  {...register('efl')}>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                  <option value="Pending">Pending</option>
                </select>
              </label>
            </div>

            <div className="form-col">
              <label className="block" htmlFor="select">
                <span className="label-span text-gray-dark">Reason for your contact</span>
                <input
                  type="text"
                  className="form-input mt-1 block w-full"
                  id="reasonOfContact"
                  name="reasonOfContact"
                  {...register('reasonOfContact')}
                />
              </label>
            </div>
          </div>

          <div className="form-row">
            <label className="block" htmlFor="textarea">
              <span className="label-span text-gray-dark">Message</span>
              <textarea
                className="form-textarea mt-1 block w-full"
                rows="3"
                id="message"
                name="message"
                {...register('message')}
              />
            </label>
          </div>
        </div>

        <div className="form-row submit-button-section">
          {/* Change this to button tag to add a disable state */}
          {/* <input type="submit" value="Send" className="btn-primary submit" disabled={loading} /> */}
          <div className="button-wrp">
            <button type="submit" className="btn-primary submit" disabled={loading}>
              Send
            </button>
          </div>
          {status && <div className={`form-feedback status-${status.type}`}>{status.message}</div>}
        </div>
      </form>
    </Forms>
  );
}
