import * as React from 'react';
import GoogleMapReact from 'google-map-react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import Layout from '../layouts/layout';
import CommonBanner from '../components/commonBanner';
import ContactPageContactForm from '../components/contactPageContactForm';
import MetaTags from '../components/SiteMeta';

const ContactWrp = styled.div`
  ${tw`relative lg:grid lg:grid-cols-2 mx-auto mb-28 md:mb-36 lg:mb-44`}
  max-width: 1724px;

  .form-section-wrp {
    ${tw`px-9 mx-auto pt-24 md:pt-36 lg:pt-44 w-full`}
    max-width: 684px;

    @media (min-width: 1500px) {
      ${tw`px-0`}
    }

    .title-wrp {
      ${tw`mb-10`}

      span {
        ${tw`uppercase text-gray-darker font-bold`}
      }

      h2 {
        ${tw`text-primary-dark`}
      }
    }

    .form-wrp {
      ${tw`pb-16`}

      .submit {
        ${tw`w-full`}

        @media (min-width: 500px) {
          max-width: 180px;
        }
      }
    }
  }

  .map-detail-wrp {
    ${tw`grid md:grid-cols-12`}

    .contact-detail-wrp {
      ${tw`bg-gray-lighter px-9 lg:px-24 py-28 md:col-span-5 lg:col-span-12`}

      h2 {
        ${tw`font-bold text-xl text-primary mb-5`}
      }

      .contact-detail-item {
        ${tw`text-gray-darker leading-8 mb-10`}

        &:last-child {
          ${tw`mb-0`}
        }

        h4 {
          ${tw`font-bold text-primary-dark`}
        }

        .contact {
          span {
            ${tw`font-bold`}
          }
        }
      }
    }

    .map-wrp {
      ${tw`md:col-span-7 lg:col-span-12`}

      img {
        ${tw`w-full`}
      }

      .acf-map {
        width: 100%;
        height: 408px;

        @media (min-width: 768px) {
          height: 100%;
        }

        @media (min-width: 1024px) {
          height: 408px;
        }
      }
    }
  }
`;

const Pin = styled.div`
  ${tw`relative flex items-center pl-5`}
  width: 140px;

  .pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #ea4336;
    position: absolute;
    transform: rotate(-45deg);
    left: 0;
    top: 50%;
    margin: -20px 0 0 -20px;

    &:after {
      content: '';
      width: 14px;
      height: 14px;
      margin: 8px 0 0 8px;
      background: #a60e0d;
      position: absolute;
      border-radius: 50%;
    }
  }

  .marker-text {
    color: #c1413e;
  }
`;

// eslint-disable-next-line react/prop-types
const Marker = ({ text }) => (
  <>
    <Pin className="marker-wrp">
      <div className="pin" />
      <div className="marker-text">{text}</div>
    </Pin>
  </>
);

// markup
const ContactUs = ({ data }) => {
  const { acfContactPage } = data?.page?.nodes[0];
  const { acfGlobalContent } = data?.page?.nodes[0];
  return (
    <Layout preFooterContent={acfGlobalContent}>
      <MetaTags
        title={acfGlobalContent.metaTitle}
        description={acfGlobalContent.metaDescription}
        image={acfGlobalContent.metaOgImage?.sourceUrl}
      />
      <main>
        <CommonBanner
          title={acfContactPage.bannerTitle}
          subtitle={acfContactPage.bannerSubtitle}
          banner={acfContactPage?.bannerImage?.localFile}
        />
        <ContactWrp className="contact-content-wrp">
          <div className="form-section-wrp">
            <div className="title-wrp">
              <span>{acfContactPage.contactFormTitle}</span>
              <h2 className="h2">{acfContactPage.contactFormTagline}</h2>
            </div>
            <div className="form-wrp">
              <ContactPageContactForm />
            </div>
          </div>
          <div className="map-detail-wrp">
            <div className="contact-detail-wrp">
              <h2>{acfContactPage.contactDetailsTitle}</h2>

              {acfContactPage.contactDetailsBranchDetails.map((item) => (
                <div className="contact-detail-item" key={item.branchName}>
                  <h4>{item.branchName}</h4>

                  {/* eslint-disable react/no-danger */}
                  {item.address && (
                    <div className="address" dangerouslySetInnerHTML={{ __html: item.address }} />
                  )}

                  {item.email && (
                    <div className="contact email">
                      <span>Email:</span> {item.email}
                    </div>
                  )}

                  {(item.mobileContactNumber || item.alternativeMobileNumber) && (
                    <div className="contact mobile">
                      <span>Mobile:</span>
                      {item.mobileContactNumber && `${' '}${item.mobileContactNumber}`}
                      {item.alternativeMobileNumber && `${' / '}${item.alternativeMobileNumber}`}
                    </div>
                  )}

                  {(item.officeContactNumber || item.alternativeOfficeNumber) && (
                    <div className="contact office">
                      <span>Office:</span>
                      {item.officeContactNumber && `${' '}${item.officeContactNumber}`}
                      {item.alternativeOfficeNumber && `${' / '}${item.alternativeOfficeNumber}`}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="map-wrp">
              <div className="acf-map" data-zoom="16">
                <div style={{ height: '100%', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyA3t0mLCZTudva-Hg6A2wD76wBH_xgjYoM' }}
                    defaultCenter={{
                      lat: parseFloat(acfContactPage.contactDetailsMapLocation.latitude),
                      lng: parseFloat(acfContactPage.contactDetailsMapLocation.longitude),
                    }}
                    defaultZoom={16}>
                    <Marker
                      lat={parseFloat(acfContactPage.contactDetailsMapLocation.latitude)}
                      lng={parseFloat(acfContactPage.contactDetailsMapLocation.longitude)}
                      text="Vision Energy Solutions"
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        </ContactWrp>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ContactPage {
    page: allWpPage(filter: { slug: { eq: "contact" } }) {
      nodes {
        acfContactPage {
          bannerTitle
          bannerSubtitle
          bannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          contactDetailsMapLocation {
            city
            country
            placeId
            latitude
            longitude
            countryShort
          }
          contactDetailsBranchDetails {
            address
            alternativeMobileNumber
            alternativeOfficeNumber
            branchName
            email
            mobileContactNumber
            officeContactNumber
          }
          contactDetailsTitle
          contactFormShortcode
          contactFormTagline
          contactFormTitle
        }
        acfGlobalContent {
          prefooterButtonLink {
            url
          }
          prefooterButtonText
          prefooterTitle
          metaTitle
          metaDescription
          metaOgImage {
            sourceUrl
          }
        }
      }
    }
  }
`;

ContactUs.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default ContactUs;
